<template>
    <div class="px-page mt-5">
        <v-tabs fixed-tabs v-model="tab">
            <v-tab>
                Allgemein
            </v-tab>
            <v-tab>
                Konsole
            </v-tab>
            <v-tab>
                Backups
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <RootServerGeneral :server="server" :service="service" :status="status" />
            </v-tab-item>
            <v-tab-item>
                <RootServerConsole :server="server" :status="status" />
            </v-tab-item>
            <v-tab-item>
                <RootServerBackups />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import RootServerGeneral from './RootServerGeneral'
import RootServerConsole from './RootServerConsole'
import RootServerBackups from './RootServerBackups'

export default {
    components: { RootServerGeneral, RootServerConsole, RootServerBackups },
    data() {
        return {
            tab: 0,
            server: {
                id: this.$route.params.id,
                hostname: '',
                cores: 0,
                memory: 0,
                disk: 0,
                password: ''
            },
            service: {
                id: 0,
                expired: false
            },
            status: {
                status: 'UNKNOWN'
            },
            statusTimer: undefined,
            templates: []
        }
    },
    created() {
        xeonserv().get('/rootservers/' + this.server.id).then(res => this.server = res.data.data)
        xeonserv().get('/rootservers/' + this.server.id + '/service').then(res => this.service = res.data.data)
        this.loadTemplates()
        this.loadStatus()
        this.loadAddresses()
        this.statusTimer = setInterval(this.loadStatus, 5000)
        this.$on('reloadstatus', this.loadStatus)
        this.$on('setserver', server => this.server = server)
    },
    beforeDestroy() {
        clearInterval(this.statusTimer)
    },
    methods: {
        loadStatus() {
            xeonserv().get('/rootservers/' + this.server.id + '/status').then(res => this.status = res.data.data)
        },
        loadTemplates() {
            xeonserv().get('/rootserver-templates').then(res => {
                this.templates = res.data.data
            })
        }
    }
}
</script>