<template>
    <v-row class="mt-5 mb-5">
        <v-col md="8">
            <v-row dense>
                <v-col md="12">
                    <v-text-field label="Name" outlined readonly dense :value="server.hostname" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Status" outlined readonly dense :value="displayStatus" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="CPU" outlined readonly dense :value="server.cores + ' Kern(e)'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="RAM" outlined readonly dense :value="server.memory + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="SSD" outlined readonly dense :value="server.disk + ' GB'" />
                </v-col>
                <v-col md="6">
                    <v-text-field label="Root Passwort" outlined readonly dense :value="server.password" @click="hidePassword = !hidePassword" :type="hidePassword ? 'password' : 'text'" />
                </v-col>
                <v-col md="12">
                    <v-text-field label="Restlaufzeit" outlined readonly dense :value="remainingTime" />
                </v-col>
                <v-col md="12">
                    <v-simple-table class="mb-5">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    IP-Adresse
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="address in addresses"
                            :key="address.id"
                            >
                                <td>
                                    {{ address.address }}
                                    <v-chip v-if="address.primary" color="primary" x-small class="ml-1">Haupt-IP</v-chip>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
                <v-col md="12" class="text-center" v-if="status.status == 'ONLINE'">
                    CPU Auslastung ({{ (status.cpu * 100).toFixed(1).replace('.', ',') }} %)
                    <v-progress-linear :value="status.cpu * 100" class="mb-5"></v-progress-linear>
                </v-col>
                <v-col md="12" class="text-center" v-if="status.status == 'ONLINE'">
                    RAM Auslastung ({{ memoryUsageGB.toFixed(3).replace('.', ',') }}  GB, {{ (memoryUsage * 100).toFixed(1).replace('.', ',') }} %)
                    <v-progress-linear :value="memoryUsage * 100" class="mb-5"></v-progress-linear>
                </v-col>
            </v-row>
        </v-col>
        <v-col md="4">
            <v-btn block v-if="status.status == 'OFFLINE'" class="mb-2 white--text" color="green" @click="startServer()">Starten</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="orange" @click="restartServer()">Neustart</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="red" @click="shutdownServer()">Herunterfahren</v-btn>
            <v-btn block v-if="status.status == 'ONLINE'" class="mb-2 white--text" color="red" @click="stopServer()">Stoppen</v-btn>
            <v-btn block class="mb-2" color="primary">Verlängern</v-btn>
            <RootServerUpgrade :server="server" :service="service" />
        </v-col>
    </v-row>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import interval from '../../../util/interval'
import RootServerUpgrade from './RootServerUpgrade'

export default {
    components: { RootServerUpgrade },
    data() {
        return {
            now: new Date(),
            hidePassword: true,
            addresses: []
        }
    },
    created() {
        xeonserv().get('/rootservers/' + this.server.id + '/addresses').then(res => this.addresses = res.data.data)
    },
    timers: {
        updateNow: { time: 1000, autostart: true, repeat: true, callback() { this.now = new Date() }}
    },
    props: {
        server: {
            type: Object
        },
        status: {
            type: Object
        },
        service: {
            type: Object
        }
    },
    methods: {
        startServer() {
            xeonserv().post('/rootservers/' + this.server.id + '/start').then(() => this.$parent.$emit('reloadstatus'))
        },
        stopServer() {
            xeonserv().post('/rootservers/' + this.server.id + '/stop').then(() => this.$parent.$emit('reloadstatus'))
        },
        restartServer() {
            xeonserv().post('/rootservers/' + this.server.id + '/restart').then(() => this.$parent.$emit('reloadstatus'))
        },
        shutdownServer() {
            xeonserv().post('/rootservers/' + this.server.id + '/shutdown').then(() => this.$parent.$emit('reloadstatus'))
        }
    },
    computed: {
        displayStatus() {
            if(this.service.expired)
                return 'Abgelaufen'
            switch(this.status.status) {
                case 'ONLINE':
                    return 'Online'
                case 'OFFLINE':
                    return 'Offline'
            }
            return 'Unbekannt'
        },
        memoryUsageGB() {
            return this.status.memory / 1073741824
        },
        memoryUsage() {
            return this.status.memory / (this.server.memory * 1073741824)
        },
        remainingTime() {
            this.now;
            return interval.format(interval.until(this.service.expire_at))
        }
    }
}
</script>