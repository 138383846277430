<template>
    <div class="mt-5 mb-5">
        <v-btn small color="primary" @click="this.rfb.sendCtrlAltDel()">Strg + Alt + Entf. drücken</v-btn>
        <v-btn small color="primary" class="ml-1" @click="sendPassword()">Passwort einfügen</v-btn>
        <div ref="vnc" class="vnc mt-2" />
    </div>
</template>

<script>
import xeonserv from '../../../apis/xeonserv'
import RFB from '@novnc/novnc/core/rfb';

export default {
    props: {
        server: {
            type: Object
        },
        status: {
            type: Object
        }
    },
    data() {
        return {
            rfb: undefined
        }
    },
    mounted() {
        this.connect()
    },
    methods: {
        connect() {
            xeonserv().get('/rootservers/' + this.server.id + '/vnc').then(res => this.connectTo(res.data.data))
        },
        connectTo(data) {
            if(this.rfb != undefined) {
                this.rfb.disconnect()
            }
            this.rfb = new RFB(this.$refs.vnc, 'wss://' + data.host + ':' + data.port + '/websockify', {
                credentials: {
                    password: data.password
                }
            })
            this.rfb.addEventListener('disconnect', () => {
                while(this.$refs.vnc.firstChild)
                    this.$refs.vnc.removeChild(this.$refs.vnc.firstChild)
                this.rfb = undefined
            })
        },
        sendKeys(keys) {
            keys.split('').forEach(k => {
                let key = k.charCodeAt(0)
                if(key == 10)
                    key = 0xFF0D
                this.rfb.sendKey(key, null)
            })
        },
        sendPassword() {
            this.sendKeys(this.server.password)
        }
    }
}
</script>

<style scoped>
.vnc {
    width: 100%;
    min-height: 600px;
    background-color: rgb(40, 40, 40);
}
</style>